import React, { useMemo } from 'react'
import styled from 'styled-components'
import UserBar from './user/UserBar'
import media from '@/ui/media'
import SearchBar from './search/SearchBar'
import { SectionType } from '@/containers/SidebarContainer'
import { useGlobalStore } from '@/stores/globalStore'
import { useTree } from '@/api/hooks/useTree'
import { layerChangeEvent } from '@/components/layout/AppBar/search/SearchItem'
import LogoIcon from '@/components/icons/LogoIcon'
import OfficeMapIcon from '@/components/icons/OfficeMapIcon'
import BackIcon from '@/components/icons/BackIcon'
import { RolesEnum, useUserStore } from '@/stores/userStore'

const Appbar: React.FC = () => {
	const role = useUserStore((state) => state.role)
	const isAdmin = role === RolesEnum.Admin

	return (
		<Wrapper>
			{isAdmin ? (
				<>
					<GridWrapper>
						<div>
							<BackItem name="Вернуться" icon={BackIcon} />
						</div>
						<LogoWrapper>
							<LogoIcon />
						</LogoWrapper>
					</GridWrapper>
					<SearchWrapper>
						<SearchBar />
					</SearchWrapper>
					<UserBar />
				</>
			) : (
				<>
					<MenuWrapper>
						<LayerItem
							name="План офиса"
							selector="layers"
							icon={OfficeMapIcon}
						/>
						<BackItem name="Вернуться" icon={BackIcon} />
						<LogoWrapper>
							<LogoIcon />
						</LogoWrapper>
					</MenuWrapper>

					<SearchWrapper>
						<SearchBar />
					</SearchWrapper>
					<UserBar />
				</>
			)}
		</Wrapper>
	)
}

export default Appbar

const LogoWrapper = styled.div`
	svg {
		height: 100%;
		max-width: 100%;
	}
`

const SearchWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const GridWrapper = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: 60px 1fr;
	gap: 16px;
`

const MenuWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 32px;
`

const BackItem = ({ name, icon: Icon }) => {
	const { layers } = useTree()
	const { activeLayer, setActiveLayer } = useGlobalStore()
	const { nodes } = layers || {}
	const current = useMemo(
		() => nodes?.find((node) => node.id == activeLayer),
		[nodes, activeLayer],
	)

	const handleLayerChange = () => {
		if (!current || !current.parent) return
		document.dispatchEvent(layerChangeEvent(current.parent))

		setActiveLayer(Number(current.parent))
	}

	if (!current || !current.parent) return null

	return (
		<Item onClick={handleLayerChange}>
			<Icon color="#004054" />
			<p>{name}</p>
		</Item>
	)
}

interface MenuItemProps {
	name: string
	selector: SectionType
	icon: React.ElementType
}

const LayerItem: React.FC<MenuItemProps> = ({ name, selector, icon: Icon }) => {
	const stateSelector = useGlobalStore((state) => state.selector)
	const setSelector = useGlobalStore((state) => state.setSelector)

	const isActive = selector === stateSelector
	const onChange = () => setSelector(isActive ? null : selector)

	return (
		<Item onClick={onChange}>
			<Icon />
			<p>{name}</p>
		</Item>
	)
}

const Item = styled.div`
	//width: 100%;
	height: 75px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	//padding: 2rem 1rem;
	position: relative;
	transition:
		background 0.3s,
		color 0.3s;

	p {
		font-weight: 400;
		font-size: 10px;
		line-height: 16px;
		text-align: center;
		margin-top: 0.8rem;
	}

	.hover-icon,
	.hover-color,
	.hover-alt,
	.hover-fill {
		transition: all 0.3s;
	}

	img {
		z-index: 2;
		user-select: none;
	}

	// color: ${(props) => props.theme.palette.text.primary};

	// .hover-icon {
	// 	fill: transparent;
	// }
	//
	// .hover-fill {
	// 	fill: ${(props) => props.theme.palette.text.primary};
	// }
	//
	// .hover-color {
	// 	fill: ${(props) => props.theme.palette.text.primary};
	// 	stroke: ${(props) => props.theme.palette.text.primary};
	// }
	//
	// .hover-alt {
	// 	fill: ${(props) => props.theme.palette.text.primary};
	// 	stroke: ${(props) => props.theme.palette.background.primary};
	// }
`

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 32px;
	padding: 0 20px;
	height: 75px;
	box-shadow: 4px 0px 40px rgba(84, 84, 84, 0.25);
	/* border-radius: 0px 0px 8px 8px; */
	z-index: 2001;
	background: #fff;
	color: #043246;

	${media.lg`
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 16px;
        padding: 0 10px;
        border-radius: 0px;
    `}
`
