import React from 'react'

const EditIcon = ({ width = 18, height = 17, color = '#EB5A00' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 18 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.3176 3.81515L14.2195 0.865152C14.0148 0.672184 13.7378 0.563858 13.449 0.563858C13.1602 0.563858 12.8831 0.672184 12.6784 0.865152L1.69535 11.2552L0.692575 15.3552C0.657982 15.505 0.659168 15.6603 0.696046 15.8097C0.732923 15.9591 0.804561 16.0988 0.905725 16.2186C1.00689 16.3384 1.13502 16.4353 1.28077 16.5022C1.42652 16.569 1.58619 16.6042 1.74813 16.6052C1.82359 16.6124 1.89962 16.6124 1.97508 16.6052L6.35035 15.6552L17.3176 5.27515C17.5213 5.0812 17.6356 4.81874 17.6356 4.54515C17.6356 4.27156 17.5213 4.0091 17.3176 3.81515ZM5.82258 14.7552L1.72174 15.5702L2.65591 11.7602L10.8734 4.00515L14.0401 7.00515L5.82258 14.7552ZM14.7473 6.28015L11.5806 3.28015L13.4173 1.55015L16.5312 4.55015L14.7473 6.28015Z"
				fill={color}
			/>
		</svg>
	)
}

export default EditIcon
