import React, { useState } from 'react'
import styled from 'styled-components'
import ReportView from './ReportView'
import InfoIcon from '@/components/icons/InfoIcon'
import { IReport } from '@/api/services/report.service'
import { translate } from '@/i18n'
import BomjReportView from '@/components/layout/Sidebar/Reports/reports/bomj-report/BomjReport'

type ReportsItemProps = {
	report: IReport
}

const reportTranslations = {
	'free-places-v1': 'Свободные места за период',
}

const translateName = (name) => {
	const reportName = reportTranslations[name]

	return reportName ? reportName : name
}

const Report: React.FC<ReportsItemProps> = ({ report }) => {
	const [open, setOpen] = useState<boolean>(false)

	return (
		<>
			<ReportWrapper>
				<ReportName>{translate(report.name)}</ReportName>
				<ReportControls>
					{/* <ReportControlIconButton onClick={() => setReport('parking')}>
                        <ExportIcon />
                    </ReportControlIconButton> */}
					<ReportControlButton onClick={() => setOpen(true)}>
						<InfoIcon />
					</ReportControlButton>
				</ReportControls>
			</ReportWrapper>
			{open &&
				(report.name === 'free-places-v1' ||
					report.name === 'non-free-places-v1') && (
					<ReportView
						report={{ ...report, name: report.name }}
						open={open}
						onClose={() => setOpen(false)}
					/>
				)}
			{open &&
				(report.name === 'bomj-users' || report.name === 'no-place-users') && (
					<BomjReportView
						report={{ ...report, name: report.name }}
						open={open}
						onClose={() => setOpen(false)}
					/>
				)}
			{/* {item.key === 'square' && (<SquareReportModal report={report} item={item} onClose={() => setReport(null)} />)}
            {item.key === 'empty' && (<EmptySeatsReportModal report={report} item={item} onClose={() => setReport(null)} />)} */}
		</>
	)
}

export default Report

const ReportWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 0;
`

const ReportName = styled.div`
	font-weight: 500;
	font-size: 1.6rem;
	line-height: 1.6rem;
	margin-right: 1.6rem;
	color: #004054;
`

const ReportControls = styled.div`
	display: flex;
	align-items: center;
`

const ReportControlButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	border: none;
	background: transparent;
	cursor: pointer;

	&:not(:last-child) {
		margin-right: 0.8rem;
	}
`

const ReportControlIconButton = styled(ReportControlButton)`
	width: 30px;
	height: 30px;
	background: #f8dc4d;
	border-radius: 50%;
`
