import { SearchButton } from '@/components/layout/AppBar/search/Search'
import Close from '@/components/Close'
import Grid from '@/components/Grid'
import Modal from '@/components/Modal/Modal'
import React, { useState, useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components'
import Pagination from '@/ui/components/Pagination/Pagination'
import { Input } from '@/ui/components/Field/Input'
import { debounce } from 'lodash'
import { useUsers } from '@/api/hooks/useUsers'
import { useUsersListerFields } from '@/api/hooks/useUsersListerFields'
import FormLoader from '@/components/ui/form/FormLoader'
import { PrimaryButton } from '@/components/ui/button/PrimaryButton'
import { DefaultButton } from '@/components/ui/button/DefaultButton'
import { makeCols } from '@/components/Employee/Employees'
import {
	makeEmployeeMobileData,
	UserExtra,
} from '@/components/Employee/makeEmployeeData'
import {
	ControlsWrapper,
	FormControls,
} from '@/components/ui/form/FormControls'
import useResponsive from '@/hooks/useResponsive'
import { useFormikContext, validateYupSchema } from 'formik'
import { useIntl } from 'react-intl'
import { translate } from '@/i18n'

type ReportModalProps = {
	isOpen: boolean
	onClose: () => void
	setFieldValue: any
}

const SelectUserModal: React.FC<ReportModalProps> = ({ isOpen, onClose }) => {
	const [currentPage, setCurrentPage] = useState<number>(1)
	const { values, setFieldValue } = useFormikContext<any>()
	const [user, setUser] = useState<any>(null)
	const [name, setName] = useState<string>('')
	const intl = useIntl()

	const { isMobile } = useResponsive()
	const { data, isLoading } = useUsers({ page: currentPage, perPage: 20, name })
	const { data: extra } = useUsersListerFields()

	const handleSelection = async () => {
		if (user) {
			setFieldValue('user', {
				id: user.value,
				name: user.label,
			})
		}
		onClose()
	}

	const handleNameChange = (e) => setName(e?.target?.value)

	const debouncedNameResponse = useMemo(() => {
		return debounce(handleNameChange, 500)
	}, [])

	useEffect(() => {
		return () => debouncedNameResponse.cancel()
	}, [])

	useEffect(() => {
		if (isOpen) {
			setName('')

			setUser({
				label: values.user.name || '',
				value: values.user.id || '',
			})
		}
	}, [isOpen, values])

	const columns = makeCols(extra?.fields)
	const users = extractFields(data?.items, columns)
	const template = `20px 1fr ${columns.map(() => `1fr`).join(' ')}`
	const dafta = makeEmployeeMobileData(data?.items, columns)

	return (
		<Modal isOpen={isOpen} onClose={onClose} maxWidth={700}>
			<Header>
				<Title>{translate('select-employee')}</Title>
				<Close color="#000" onClick={onClose} />
			</Header>
			<div>
				<Grid>
					<Filters>
						<FiltersItem>
							<Input
								$fullWidth
								placeholder={intl.formatMessage({ id: 'search' }) + '...'}
								onChange={debouncedNameResponse}
							/>
						</FiltersItem>
						<FiltersSearch>
							<SearchButton />
						</FiltersSearch>
					</Filters>

					{!isMobile && (
						<Grid.RowHeader $cols={template}>
							<Grid.Item />
							<Grid.Item>{translate('full-name')}</Grid.Item>
							{columns.map((column) => (
								<Grid.Item key={column.uid}>{column.label}</Grid.Item>
							))}
						</Grid.RowHeader>
					)}

					{data && data.items && data?.items.length ? (
						<>
							{!isMobile &&
								users.map((userData) => (
									<EmployeeItem
										key={userData.id}
										user={userData}
										selected={user}
										template={template}
										handleSelection={setUser}
									/>
								))}

							{isMobile &&
								dafta.map((userData) => (
									<EmployeeMobileItem
										key={userData.id}
										user={userData}
										selected={user}
										handleSelection={setUser}
									/>
								))}

							<PaginationWrapper>
								<Pagination
									inverse
									currentPage={currentPage}
									total={data.total}
									itemsPerPage={20}
									handlePageChange={setCurrentPage}
								/>
							</PaginationWrapper>
						</>
					) : (
						<NotFound>Ничего не найдено</NotFound>
					)}

					<FormLoader isLoading={isLoading} />

					<ControlsWrapper>
						<FormControls>
							<PrimaryButton $fullWidth type="submit" onClick={handleSelection}>
								{translate('select')}
							</PrimaryButton>
							<DefaultButton $fullWidth onClick={onClose} type="button">
								{translate('cancel')}
							</DefaultButton>
						</FormControls>
					</ControlsWrapper>
				</Grid>
			</div>
		</Modal>
	)
}

export default SelectUserModal

const EmployeeMobileItem: React.FC<{
	user: any
	selected: any
	handleSelection: React.Dispatch<any>
}> = ({ user, selected, handleSelection }) => {
	const userSelected = selected?.value || null
	const handleOnClick = () => {
		handleSelection({ label: user.display, value: user.id })
	}

	return (
		<SearchData onClick={handleOnClick} $selected={userSelected == user.id}>
			<Name>{user.display}</Name>
			{user.fields.map((field, idx) => (
				<Property key={idx}>
					<PropertyName>{field.name}</PropertyName>
					<PropertyValue>{field.value}</PropertyValue>
				</Property>
			))}
		</SearchData>
	)
}

const EmployeeItem: React.FC<{
	user: any
	template: any
	selected: any
	handleSelection: React.Dispatch<any>
}> = ({ user, template, selected, handleSelection }) => {
	const { isMobile } = useResponsive()
	const userSelected = selected?.value || null
	const handleOnClick = () => {
		handleSelection({ label: user.display, value: user.id })
	}

	return (
		<Grid.Row
			$cols={template}
			as="label"
			$label
			style={{ padding: '1rem 0' }}
			onClick={handleOnClick}
		>
			<Grid.Item>
				<input type="radio" name="employee" value={user.id} />
			</Grid.Item>
			<Grid.Item>{user.display}</Grid.Item>
			{user.data.map((prop, idx) => (
				<Grid.Item key={idx}>{prop}</Grid.Item>
			))}
		</Grid.Row>
	)
}

const extractFields = (
	data: any[] | undefined,
	columns: UserExtra[] | undefined,
) => {
	if (!data || !columns) return []

	return data.map((user) => {
		const userData: any = []

		columns.forEach((col) => {
			userData.push(user[col.uid] || '')
		})

		return { id: Number(user.id), display: user.display, data: userData }
	})
}

const NotFound = styled.div`
	width: 100%;
	text-align: center;
	padding: 12px 0;
`

const Filters = styled.div`
	display: grid;
	grid-template-columns: 1fr 60px;
	column-gap: 14px;
	padding: 6px 0 14px 0;
`
const FiltersSearch = styled.div``
const FiltersItem = styled.div``

const PaginationWrapper = styled.div`
	padding: 2rem 0 0;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const Title = styled.div`
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 2.4rem;
	color: inherit;
`

const PropertyName = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: inherit;
`
const PropertyValue = styled(PropertyName)`
	font-weight: 700;
	padding-left: 8px;
`

const Property = styled.div`
	font-size: 12px;
	line-height: 16px;
	display: grid;
	grid-template-columns: auto 1fr;
	margin-top: 6px;
	color: inherit;
`

const Name = styled.div`
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
	color: inherit;
`

const SearchData = styled.div<{ $selected?: boolean }>`
	border: 1px solid rgba(0, 0, 0, 0.25);
	box-sizing: border-box;
	border-radius: 3px;
	padding: 10px;
	color: #000;

	${({ $selected }) =>
		$selected &&
		css`
			background: #eb5a00;
			border: 1px solid #eb5a00;
			border-radius: 3px;
			color: #fff;
		`}

	&:not(:last-child) {
		margin-bottom: 10px;
	}
`
