import React from 'react'
import styled, { css } from 'styled-components'
import { SectionType } from '@/containers/SidebarContainer'
import { useGlobalStore } from '@/stores/globalStore'
import { media } from '@/ui/media'
import { useProjectStore } from '@/stores/projectStore'

interface MenuItemProps {
	name: string
	selector: SectionType
	icon: React.ElementType
}

const MenuItem: React.FC<MenuItemProps> = ({ name, selector, icon: Icon }) => {
	// data selectors
	const stateSelector = useGlobalStore((state) => state.selector)
	const setSelector = useGlobalStore((state) => state.setSelector)
	const fullMode = useProjectStore((state) => state.fullMode)
	const simpleModeSelector =
		selector === 'bookings' ||
		selector === 'employees' ||
		selector === 'reports'

	const isActive = selector === stateSelector
	const onChange = () => setSelector(isActive ? null : selector)

	if (!fullMode && simpleModeSelector) return null

	return (
		<Item $active={isActive} onClick={onChange}>
			<Icon />
			<p>{name}</p>
		</Item>
	)
}

export default MenuItem

const Item = styled.div<{ $active?: boolean }>`
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	padding: 2rem 1rem;
	position: relative;
	transition:
		background 0.3s,
		color 0.3s;
	color: ${(props) => props.theme.palette.text.secondary};

	p {
		font-weight: 400;
		font-size: 10px;
		line-height: 16px;
		text-align: center;
		margin-top: 0.8rem;
	}

	.hover-icon,
	.hover-color,
	.hover-alt,
	.hover-fill {
		transition: all 0.3s;
	}

	&:hover {
		background: ${(props) => props.theme.palette.background.primary};
		color: #fff;

		.hover-icon {
			fill: transparent;
		}

		.hover-fill {
			fill: #fff;
		}

		.hover-color {
			fill: #fff;
			stroke: #fff;
		}

		.hover-alt {
			fill: #fff;
			stroke: ${(props) => props.theme.palette.background.primary};
		}
	}

	img {
		z-index: 2;
		user-select: none;
	}

	${({ $active }) =>
		$active &&
		css`
			background: ${(props) => props.theme.palette.background.primary};
			color: #fff;

			.hover-icon {
				fill: transparent;
			}

			.hover-fill {
				fill: #fff;
			}

			.hover-color {
				fill: #fff;
				stroke: #fff;
			}

			.hover-alt {
				fill: #fff;
				stroke: ${(props) => props.theme.palette.background.primary};
			}
		`}

	${media.lg`
        height: 75px;

        p {
            display: none;
        }
    `}
`
