import React, { useState, useRef, useCallback } from 'react'
import styled from 'styled-components'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRange } from 'react-date-range'
import { Portal } from 'react-portal'
import useOnClickOutside from '@/hooks/useOnClickOutside'
import { animated, useTransition } from '@react-spring/web'
import { easePoly } from 'd3-ease'
import { addMinutes } from 'date-fns'
import useResponsive from '@/hooks/useResponsive'
import { endOfDay } from 'date-fns'
import { translate } from '@/i18n'
import { dateFnsLocale } from '@/App'
import { useFloating } from '@floating-ui/react-dom'

const ReportDateSelector = ({ selection, open, setSelection, setOpen }) => {
	const { isPhone } = useResponsive()

	let { refs, floatingStyles } = useFloating()

	const ref = useRef<any>()

	const handleSelect = (ranges) => {
		const { startDate, endDate } = ranges.selection
		let end = endDate ? endDate : addMinutes(startDate, 30)

		if (startDate == endDate) {
			end = endOfDay(end)
		}

		setSelection({ startDate, endDate: end, key: 'selection' })
	}

	const transitions = useTransition(open, {
		from: { opacity: 0, scale: 0.8 },
		enter: { opacity: 1, scale: 1 },
		leave: { opacity: 0, scale: 0.8 },
		reverse: open,
		config: {
			duration: 300,
			easing: easePoly.exponent(2),
		},
	})

	useOnClickOutside(
		ref,
		useCallback(() => setOpen(false), []),
	)

	return (
		<ShowContainer ref={refs.setReference}>
			{transitions(
				(style, item) =>
					item && (
						<Portal>
							<ShowPicker
								ref={refs.setFloating}
								style={{ ...floatingStyles, ...style }}
							>
								<Wrapper ref={ref}>
									<DateRange
										ranges={[selection]}
										onChange={handleSelect}
										locale={dateFnsLocale}
										months={2}
										showDateDisplay={false}
										direction={isPhone ? 'vertical' : 'horizontal'}
									/>
									<AcceptButton onClick={() => setOpen(!open)}>
										{translate('select-period')}
									</AcceptButton>
								</Wrapper>
							</ShowPicker>
						</Portal>
					),
			)}
		</ShowContainer>
	)
}

export default ReportDateSelector

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	/* align-items: flex-end; */
`

const AcceptButton = styled.button`
	border: none;
	outline: none;
	background-color: #eb5a00;
	color: #fff;
	padding: 15px;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	cursor: pointer;

	&:hover {
		background-color: #098f9c;
	}
`

const ShowPicker = styled(animated.div)`
	box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
	z-index: 12001;
	max-width: 100%;
	max-height: calc(100% - 75px);
	overflow: auto;
`

const ShowContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`
