import React from 'react'
import styled, { css } from 'styled-components'

type PaginationProps = {
	total: number
	currentPage: number
	itemsPerPage?: number
	handlePageChange: Function
	inverse?: boolean
}

const Pagination: React.FC<PaginationProps> = ({
	total,
	currentPage,
	itemsPerPage = 20,
	handlePageChange,
	inverse = false,
}) => {
	const pagesCount = Math.ceil(total / itemsPerPage)
	const pages = range(pagesCount, currentPage)

	const renderPageNumbers = pages.map(({ key, value }) =>
		value ? (
			<PaginationItem
				$active={currentPage === value}
				$inverse={inverse}
				key={key}
				onClick={handlePageChange.bind(null, value)}
			>
				{value}
			</PaginationItem>
		) : (
			<PaginationItem key={key} $dots $inverse={inverse}>
				...
			</PaginationItem>
		),
	)

	if (pagesCount < 2) return null

	return <PaginationList>{renderPageNumbers}</PaginationList>
}

export default Pagination

const range = (total, currentPage) => {
	let beforePages = currentPage === total ? currentPage - 1 : currentPage - 1
	let afterPages = currentPage === 1 ? currentPage + 1 : currentPage + 1
	const range: Array<{ key: string; value: number | null }> = []

	if (currentPage > 2) {
		if (total > 3) {
			range.push({ key: 'page1', value: 1 })
		}
		if (currentPage > 3) {
			range.push({ key: 'before', value: null })
		}
	}

	if (currentPage === total) {
		beforePages = beforePages - 1
	} else if (currentPage === total - 1) {
		beforePages = beforePages
	}

	if (currentPage === 1) {
		afterPages = afterPages + 1
	} else if (currentPage === 2) {
		afterPages = afterPages
	}

	for (let pageLength = beforePages; pageLength <= afterPages; pageLength++) {
		if (pageLength > total) {
			continue
		}
		if (pageLength == 0) {
			pageLength = pageLength + 1
		}
		range.push({ key: `page${pageLength}`, value: pageLength })
	}

	if (currentPage < total - 1) {
		if (currentPage < total - 2) {
			range.push({ key: 'after', value: null })
		}
		if (total > 3) {
			range.push({ key: 'total', value: total })
		}
	}

	return range.filter((r) => r.value == null || r.value > 0)
}

const PaginationList = styled.ul`
	list-style: none;
	display: flex;
	min-height: 30px;
`

const PaginationItem = styled.li<{
	$inverse: boolean
	$dots?: boolean
	$active?: boolean
}>`
	width: 30px;
	height: 30px;

	border-radius: 50%;

	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	font-size: 1.4rem;
	/* line-height: 30px; */
	text-align: center;
	user-select: none;

	${({ $dots }) =>
		$dots
			? css`
					font-weight: 700;
					font-size: 1.6rem;
					line-height: 3rem;
					text-align: center;
					color: #fff;
					cursor: default;
			  `
			: css`
					color: #fff;
					cursor: pointer;
					background: transparent;
			  `}

	${({ $inverse }) =>
		$inverse &&
		css`
			color: #eb5a00;
		`}
    
    ${({ $active, $inverse }) => {
		if ($active) {
			if ($inverse) {
				return css`
					color: #ffffff;
					background: #eb5a00;
				`
			} else {
				return css`
					color: #eb5a00;
					background: #ffffff;
				`
			}
		}

		return css``
	}}

    &:not(:last-child) {
		margin-right: 1rem;
	}
`
