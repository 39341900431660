import React from 'react'

const OfficeMapIcon = ({ width = 29, height = 26 }) => {
	return (
		<svg
			width={width}
			height={height}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				className="hover-icon"
				x=".179"
				y=".024"
				width="28.583"
				height="25.667"
				rx="1"
				fill="#004054"
			/>
			<path
				d="M12.723 8.066v-4.71M10.43 12.857l15.666.068M2.846 12.925l2.478.002M21.121 16.44v2.9"
				stroke="#fff"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path d="M21.121 19.371H27.01" stroke="#fff" strokeWidth="1.5" />
			<mask id="a" fill="#fff">
				<rect x="1.846" y="1.79" width="25.164" height="22.15" rx="1" />
			</mask>
			<rect
				x="1.846"
				y="1.79"
				width="25.164"
				height="22.15"
				rx="1"
				stroke="#fff"
				strokeWidth="3"
				mask="url(#a)"
			/>
		</svg>
	)
}

export default OfficeMapIcon
