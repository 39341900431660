import { sizes } from '@/ui/media'
import { DefaultTheme } from 'styled-components'

export const blueTheme: DefaultTheme = {
	grid: {
		gutter: 8,
		breakpoints: sizes,
	},
	palette: {
		default: {
			main: '#fff',
			text: '#004054',
		},
		accent: {
			main: '#F8DC4D',
		},
		primary: {
			main: '#EB5A00',
		},
		background: {
			primary: '#EB5A00',
			default: '#fff',
		},
		text: {
			primary: '#004054',
			secondary: '#000',
			label: '#fff',
			input: 'rgba(0, 0, 0, 0.25)',
		},
	},
}

export default blueTheme
