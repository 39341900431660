import { Group, Rect, Text } from 'react-konva'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getMapCoord } from '@/components/shared/map/polygon/Polygon'
import { useMapStore } from '@/stores/mapStore'
import { useGlobalStore } from '@/stores/globalStore'
import {
  defaultMapPointImage,
  getImageLink
} from '@/components/shared/map/point/Point'
import { API } from '@/api'
import useImage from 'use-image'
import {useSettingsSelector} from "@/hooks/settings/use-settings-selector";

const useMapAvatar = (bookingData, node, url, sourceType) => {
  const imgSrc = bookingData
    ? API.user.getAvatar({ userId: Number(bookingData.user_id), url })
    : getImageLink(node?.icon, url) || getImageLink(defaultMapPointImage, url)
  const [pointImg] = useImage(imgSrc, sourceType)

  return { image: pointImg }
}

const BasicPoint = (props) => {
  const {
    colors,
    point,
    options,
    nodes,
    bookings,
    hasDepartment,
    bookingData,
    allBookings,
    available,
    node,
    animating,
    current,
    url,
    sourceType
  } = props

  const layers = useMapStore((state) => state.layers)
  const setSeat = useGlobalStore((state) => state.setSeat)
  const setSeatEmployee = useGlobalStore((state) => state.setSeatEmployee)
  const [width, height] = useMapStore((state) => state.size)
  const setTooltip = useMapStore((state) => state.setTooltip)

  const textRef = useRef<any>(null)
  const groupRef = useRef<any>(null)
  const [coord, setCoord] = useState({ x: 0, y: 0, pX: 0 })

  const { id, x, y, name, type_uid, plugin_data } = point
  const { labelSize, fontSize, color, borderWidth, wrapText } = options

  const isEmployeeVisible = layers['employees']
  const { image } = useMapAvatar(isEmployeeVisible ? bookingData : null, node, url, sourceType)
  const text = isEmployeeVisible ? bookingData?.display || name : name
  const displayText = wrapText ? text.split(' ').join('\n') : text

  const onSelect = useCallback(() => {
    setSeat(Number(point.id))
    if (bookingData) {
      setSeatEmployee(Number(bookingData.user_id))
    } else {
      setSeatEmployee(null)
    }
  }, [setSeat, setSeatEmployee, bookingData, point])

  const onMouseEnterHandler = React.useCallback((e) => {
    const container = e.target.getStage()?.container()

    if (container) {
      container.style.cursor = 'pointer'
    }
  }, [])

  const onMouseLeaveHandler = React.useCallback((e) => {
    const container = e.target.getStage()?.container()

    if (container) {
      container.style.cursor = 'default'
    }
  }, [])

  const onGroupMouseEnterHandler = React.useCallback(
    (e) => {
      setTooltip(name)
    },
    [setTooltip, name]
  )

  const onGroupMouseLeaveHandler = React.useCallback(
    (e) => {
      setTooltip(null)
    },
    [setTooltip]
  )

  const getColor = useMemo(() => {
    if (!hasDepartment || !Object.keys(colors).length) return node?.border
    const color = colors[hasDepartment]

    if (!hasDepartment || !color) return node?.border
    return color
  }, [point, node, hasDepartment, colors])

  useEffect(() => {
    if (width > 0 && height > 0) {
      const pointX =
        -textRef.current?.textWidth / 2 + (width * labelSize) / 2 ||
        (width * labelSize) / 2
      setCoord({
        x: getMapCoord(width, x),
        y: getMapCoord(height, y),
        pX: Number(pointX)
      })
    }
  }, [textRef.current, image, width, height, displayText])

  useEffect(() => {
    if (!groupRef.current) return
    groupRef.current.on('mouseenter', onGroupMouseEnterHandler)
    groupRef.current.on('mouseleave', onGroupMouseLeaveHandler)
  }, [groupRef.current])

  return (
    <Group
      x={coord.x}
      y={coord.y}
      offsetX={(width * labelSize) / 2}
      offsetY={(width * labelSize) / 2}
      onClick={onSelect}
      onTap={onSelect}
      listening={true}
    >
      <Group
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        opacity={animating ? 1 : available ? 1 : 0.3}
        id={'point' + point.id}
        ref={groupRef}
      >
        <Rect
          width={width * labelSize}
          height={width * labelSize}
          stroke={current ? '#ff0000' : getColor}
          strokeWidth={current ? width * labelSize * 0.1 : fontSize * borderWidth}
          fill={node?.background}
          cornerRadius={node?.radius}
          shadowForStrokeEnabled={false}
          perfectDrawEnabled={false}
          listening={available}
        />
        {image && (
          <Rect
            stroke={current ? '#ff0000' : 'transparent'}
            strokeWidth={current ? width * labelSize * 0.1 : 0}
            width={width * labelSize}
            height={width * labelSize}
            cornerRadius={node?.radius}
            fillPatternImage={image}
            fillPatternScaleX={(width * labelSize) / image?.width}
            fillPatternScaleY={(width * labelSize) / image?.width}
            shadowForStrokeEnabled={false}
            perfectDrawEnabled={false}
          />
        )}
      </Group>
      <Text
        ref={textRef}
        text={displayText}
        align="center"
        fontSize={fontSize}
        fill={color}
        y={width * 1.1 * labelSize}
        x={coord.pX}
        listening={available}
        perfectDrawEnabled={false}
      />
    </Group>
  )
}

export default BasicPoint
