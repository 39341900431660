const LogoIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="280"
			height="80"
			viewBox="0 0 280 80"
			fill="none"
			style={{ shapeRendering: 'geometricPrecision' }}
		>
			<div
				id="in-page-channel-node-id"
				data-channel-name="in_page_channel_VtmdxP"
			/>
			<path
				d="M46.0791 17L0 24.2967V29.5943L46.0791 22.2976V17Z"
				fill="#F05C22"
			/>
			<path
				d="M31.6856 31.5934V36.5911H26.488V48.3857H20.0909V36.5911H14.8932V31.5934H31.6856V31.5934Z"
				fill="#00304F"
			/>
			<path
				d="M46.079 31.5934V36.3912H39.3821V48.3857H32.985V31.5934H46.079Z"
				fill="#00304F"
			/>
			<path
				d="M13.9936 43.6879C12.4943 44.1876 11.5947 44.2876 11.095 44.2876C7.19673 44.2876 6.597 41.4889 6.597 39.8896C6.597 37.4907 8.29623 35.6915 10.995 35.6915C12.1945 35.6915 13.194 35.8914 14.0936 36.2912V31.8932H13.8937C13.194 31.7933 11.7946 31.3935 9.8955 31.3935C2.59882 31.3935 0 35.7915 0 40.0895C0 45.1872 3.39845 48.7856 9.69559 48.7856C10.7951 48.7856 12.1945 48.6856 13.9936 48.1858V43.6879Z"
				fill="#00304F"
			/>
			<path
				d="M0 63.079L46.0791 55.7824V50.4848L0 57.7815V63.079Z"
				fill="#F05C22"
			/>
			<path
				d="M75.3657 47.9859C74.3662 48.2858 72.9668 48.5856 71.1676 48.5856C65.3703 48.5856 62.8714 44.5874 62.8714 40.3894C62.8714 35.9914 65.2703 31.5934 71.3676 31.5934C72.8669 31.5934 73.9664 31.7933 75.1658 32.1931L75.0659 33.5924C73.2667 32.9927 71.7674 32.9927 71.2676 32.9927C67.0695 32.9927 64.5706 36.2912 64.5706 40.3894C64.5706 44.5874 67.4693 47.2862 71.0677 47.2862C72.567 47.2862 74.0663 46.9864 75.3657 46.4866V47.9859V47.9859Z"
				fill="black"
			/>
			<path
				d="M85.6611 36.2912V37.5906H81.8628V48.4857H80.3635V37.4907H76.5652V36.1913H85.6611V36.2912Z"
				fill="black"
			/>
			<path
				d="M93.3576 47.3862C94.7569 47.3862 97.0559 46.1867 97.0559 42.3885C97.0559 38.5902 94.7569 37.3907 93.3576 37.3907C91.9582 37.3907 89.6592 38.5902 89.6592 42.3885C89.6592 46.1867 91.8582 47.3862 93.3576 47.3862ZM88.1599 53.6833V38.6901L88.06 36.3912H89.4593V38.8901H89.5593C89.9591 37.7906 90.9587 36.1913 93.3576 36.1913C96.756 36.1913 98.5552 38.5902 98.5552 42.4884C98.5552 46.9864 96.0563 48.7856 93.3576 48.7856C91.7583 48.7856 90.4589 47.786 89.6592 46.3866H89.5593V47.5861V53.7833H88.1599V53.6833Z"
				fill="black"
			/>
			<path
				d="M106.552 47.3862C109.35 47.3862 110.65 44.6874 110.65 42.3884C110.65 39.9895 109.45 37.3907 106.552 37.3907C103.753 37.3907 102.453 40.0895 102.453 42.3884C102.453 44.6874 103.753 47.3862 106.552 47.3862ZM106.552 36.0913C109.95 36.0913 112.149 38.5902 112.149 42.3884C112.149 46.1867 109.85 48.6856 106.552 48.6856C103.153 48.6856 100.954 46.1867 100.954 42.3884C100.954 38.5902 103.153 36.0913 106.552 36.0913Z"
				fill="black"
			/>
			<path
				d="M117.746 30.7937C118.746 31.9932 119.846 32.1931 120.545 32.1931C121.145 32.1931 122.244 32.0931 123.344 30.7937L124.044 31.5933C123.144 32.6928 121.945 33.4925 120.545 33.4925C119.146 33.4925 117.946 32.7928 117.047 31.5933L117.746 30.7937ZM116.847 36.2912V45.1872V46.4866H116.947L117.746 45.1872L124.044 36.2912H125.743V48.4857H124.244V39.5897V38.2903H124.144L123.344 39.5897L117.047 48.4857H115.348V36.2912H116.847Z"
				fill="black"
			/>
			<path
				d="M137.138 36.2912V37.5906H133.339V48.4857H131.84V37.4907H128.042V36.1913H137.138V36.2912Z"
				fill="black"
			/>
			<path
				d="M144.734 47.3862C146.134 47.3862 148.433 46.1867 148.433 42.3885C148.433 38.5902 146.134 37.3907 144.734 37.3907C143.335 37.3907 141.036 38.5902 141.036 42.3885C141.036 46.1867 143.335 47.3862 144.734 47.3862ZM139.537 53.6833V38.6901L139.437 36.3912H140.836V38.8901H140.936C141.336 37.7906 142.335 36.1913 144.734 36.1913C148.133 36.1913 149.932 38.5902 149.932 42.4884C149.932 46.9864 147.433 48.7856 144.734 48.7856C143.135 48.7856 141.836 47.786 141.036 46.3866H140.936V47.5861V53.7833H139.537V53.6833Z"
				fill="black"
			/>
			<path
				d="M158.728 42.2885C156.529 42.2885 153.93 42.6883 153.93 45.0872C153.93 46.3866 154.93 47.3862 156.329 47.3862C158.628 47.3862 160.127 45.7869 160.127 43.1881V42.2885H158.728V42.2885ZM153.73 36.8909C154.73 36.3912 155.929 36.0913 157.328 36.0913C161.227 36.0913 161.627 38.99 161.627 40.5893V45.7869L161.726 48.4857H160.427V46.3866C159.428 48.2858 157.728 48.6856 156.429 48.6856C153.83 48.6856 152.431 47.0863 152.431 45.2871C152.431 41.2889 156.929 41.089 159.428 41.089H160.227V40.6892C160.227 39.7896 160.227 37.4907 157.428 37.4907C156.229 37.4907 154.93 37.7905 153.83 38.3903V36.8909H153.73Z"
				fill="black"
			/>
			<path
				d="M167.224 36.2912V41.4889H173.821V36.2912H175.32V48.4857H173.821V42.7883H167.224V48.4857H165.725V36.2912H167.224Z"
				fill="black"
			/>
			<path
				d="M187.415 37.8905C187.015 37.6906 185.915 37.3907 184.616 37.3907C181.817 37.3907 180.018 39.4898 180.018 42.3884C180.018 45.8869 182.217 47.3862 184.716 47.3862C185.616 47.3862 186.515 47.1863 187.415 46.8864L187.515 48.1858C186.515 48.5856 185.716 48.6856 184.716 48.6856C180.718 48.6856 178.419 46.0868 178.419 42.3884C178.419 39.1899 180.418 36.0913 184.516 36.0913C185.016 36.0913 186.115 36.0913 187.415 36.5911V37.8905Z"
				fill="black"
			/>
			<path
				d="M197.91 36.2912V37.5906H191.913V48.4857H190.413V36.2912H197.91Z"
				fill="black"
			/>
			<path
				d="M205.607 42.2885C203.408 42.2885 200.809 42.6883 200.809 45.0872C200.809 46.3866 201.808 47.3862 203.208 47.3862C205.507 47.3862 207.006 45.7869 207.006 43.1881V42.2885H205.607V42.2885ZM200.609 36.8909C201.608 36.3912 202.808 36.0913 204.207 36.0913C208.105 36.0913 208.505 38.99 208.505 40.5893V45.7869L208.605 48.4857H207.306V46.3866C206.306 48.2858 204.607 48.6856 203.308 48.6856C200.709 48.6856 199.309 47.0863 199.309 45.2871C199.309 41.2889 203.807 41.089 206.306 41.089H207.106V40.6892C207.106 39.7896 207.106 37.4907 204.307 37.4907C203.108 37.4907 201.808 37.7905 200.709 38.3903V36.8909H200.609Z"
				fill="black"
			/>
			<path
				d="M213.803 41.4889H215.002C217.801 41.4889 217.901 39.8896 217.901 39.3898C217.901 37.9905 216.702 37.3907 215.002 37.3907C213.803 37.3907 212.903 37.6906 212.004 38.0904V36.791C213.103 36.2912 214.203 36.1913 215.202 36.1913C218.201 36.1913 219.4 37.6906 219.4 39.4898C219.4 40.8891 218.601 41.7887 217.601 42.0886V42.1886C218.601 42.3885 220 43.0881 220 45.1872C220 47.0863 218.501 48.7856 215.202 48.7856C214.303 48.7856 213.003 48.5856 211.504 47.9859L211.704 46.5866C212.803 47.1863 213.903 47.4861 215.202 47.4861C216.702 47.4861 218.401 46.8864 218.401 45.1872C218.401 43.1881 216.801 42.8882 215.002 42.8882H213.803V41.4889V41.4889Z"
				fill="black"
			/>
			<path
				d="M64.5706 20.0986H68.0691V20.6983H65.1704V26.9954H64.5706V20.0986Z"
				fill="black"
			/>
			<path
				d="M69.6683 29.0945H69.0686V22.8973V21.8978H69.6683V22.8973C69.9682 22.1976 70.468 21.7978 71.1676 21.7978C71.6674 21.7978 72.0672 21.8978 72.3671 22.0977C72.667 22.2976 72.9668 22.5975 73.0668 22.9973C73.2667 23.3971 73.2667 23.7969 73.2667 24.2967C73.2667 24.7965 73.1667 25.1963 73.0668 25.4961C72.9668 25.9959 72.8669 26.1958 72.567 26.3957C72.3671 26.5956 72.1672 26.6956 71.8673 26.7955C71.6674 26.9955 71.3676 26.9955 71.1676 26.9955C70.9677 26.9955 70.7678 26.9955 70.5679 26.8955C70.368 26.7955 70.1681 26.6956 70.0681 26.4957C69.9682 26.3957 69.7683 26.1958 69.6683 25.9959V26.4957V29.0945ZM69.6683 24.3966C69.6683 24.8964 69.7683 25.1963 69.8682 25.5961C69.9682 25.896 70.1681 26.0959 70.468 26.2958C70.6679 26.3957 70.9677 26.4957 71.1676 26.4957C71.3676 26.4957 71.6674 26.3957 71.8673 26.2958C72.0672 26.1958 72.2672 25.896 72.4671 25.5961C72.567 25.2962 72.667 24.8964 72.667 24.3966C72.667 23.8969 72.567 23.597 72.4671 23.2971C72.3671 22.9973 72.1672 22.7974 71.8673 22.5975C71.6674 22.4975 71.3676 22.3975 71.1676 22.3975C70.8678 22.3975 70.6679 22.4975 70.468 22.5975C70.1681 22.7974 69.9682 22.9973 69.8682 23.2971C69.7683 23.597 69.6683 23.9968 69.6683 24.3966Z"
				fill="black"
			/>
			<path
				d="M74.3662 28.9946L74.4661 28.4948C74.6661 28.4948 74.766 28.5948 74.9659 28.5948C75.1658 28.5948 75.2658 28.4948 75.3657 28.3948C75.4657 28.2949 75.5656 28.095 75.6656 27.7951L75.8655 27.0954L74.0663 21.8978H74.766L75.9655 25.796L76.1654 26.2958L76.3653 25.796L77.6647 21.9978H78.2644L76.1654 28.095C76.0654 28.3948 75.8655 28.6947 75.7656 28.8946C75.5657 29.0945 75.3657 29.0945 75.0659 29.0945C74.766 29.0945 74.4662 29.0945 74.3662 28.9946Z"
				fill="black"
			/>
			<path
				d="M79.264 21.8978H83.0622V26.8955H82.4625V22.4975H79.9636V26.9955H79.3639V21.8978H79.264Z"
				fill="black"
			/>
			<path
				d="M84.6615 21.8978H88.4598V26.8955H87.96V22.4975H85.3612V26.9955H84.7615V21.8978H84.6615Z"
				fill="black"
			/>
			<path
				d="M90.259 22.7974V22.1977C90.7587 21.9978 91.2585 21.8978 91.7583 21.8978C92.2581 21.8978 92.6579 21.9978 92.9577 22.1977C93.2576 22.3976 93.3576 22.6974 93.4575 22.8973C93.5575 23.1972 93.5575 23.3971 93.5575 23.697V25.796V26.8955H93.0577V26.0959C92.7578 26.6956 92.1581 26.9955 91.4584 26.9955C91.0586 26.9955 90.7587 26.8955 90.5588 26.7956C90.3589 26.6956 90.159 26.4957 90.0591 26.2958C89.9591 26.0959 89.8592 25.896 89.8592 25.5961C89.8592 24.8964 90.159 24.4966 90.6588 24.1968C91.1586 23.8969 91.8582 23.8969 92.7578 23.8969H92.9577V23.697C92.9577 22.7974 92.5579 22.3976 91.7583 22.3976C91.2585 22.3976 90.7587 22.4975 90.259 22.7974ZM92.9577 24.3967H92.358C91.7583 24.3967 91.2585 24.4966 90.9587 24.6965C90.6588 24.8964 90.4589 25.1963 90.4589 25.5961C90.4589 25.796 90.4589 25.9959 90.5588 26.0959C90.6588 26.1958 90.7587 26.3958 90.9587 26.3958C91.1586 26.4957 91.2585 26.4957 91.4584 26.4957C91.7583 26.4957 92.0582 26.3958 92.358 26.2958C92.5579 26.1958 92.7578 25.896 92.8578 25.6961C92.8578 25.3962 92.9577 25.0963 92.9577 24.7965V24.3967Z"
				fill="black"
			/>
			<path
				d="M98.5552 21.8978H99.1549V24.0968L101.454 21.8978H102.254L99.7547 24.1968L102.453 26.8955H101.554L99.055 24.3967V26.8955H98.4552V21.8978H98.5552Z"
				fill="black"
			/>
			<path
				d="M103.053 24.3966C103.053 23.9968 103.053 23.697 103.153 23.3971C103.253 23.0972 103.353 22.7974 103.553 22.5975C103.753 22.3975 103.953 22.1976 104.253 21.9977C104.552 21.8978 104.852 21.7978 105.252 21.7978C105.752 21.7978 106.152 21.8978 106.552 22.0977C106.951 22.2976 107.151 22.5975 107.351 22.9973C107.551 23.3971 107.651 23.7969 107.651 24.2967C107.651 24.7965 107.551 25.1963 107.351 25.5961C107.151 25.9959 106.951 26.2958 106.552 26.4957C106.252 26.6956 105.752 26.7955 105.252 26.7955C104.752 26.7955 104.353 26.6956 103.953 26.4957C103.653 26.2958 103.353 25.9959 103.153 25.5961C103.153 25.3962 103.053 24.8964 103.053 24.3966ZM103.653 24.3966C103.653 24.5965 103.653 24.8964 103.753 25.0963C103.853 25.2962 103.953 25.5961 104.053 25.796C104.153 25.9959 104.353 26.1958 104.552 26.2958C104.752 26.3957 105.052 26.4957 105.352 26.4957C105.652 26.4957 105.952 26.3957 106.152 26.2958C106.352 26.1958 106.552 25.9959 106.652 25.796C106.751 25.5961 106.851 25.3962 106.951 25.0963C107.051 24.8964 107.051 24.5965 107.051 24.3966C107.051 24.0968 106.951 23.7969 106.851 23.497C106.751 23.1972 106.552 22.8973 106.352 22.6974C106.152 22.4975 105.752 22.3975 105.352 22.3975C104.952 22.3975 104.652 22.4975 104.353 22.6974C104.153 22.8973 103.953 23.1972 103.853 23.497C103.753 23.7969 103.653 24.0968 103.653 24.3966Z"
				fill="black"
			/>
			<path
				d="M109.75 21.8978L111.349 25.5961L111.549 26.2958L111.849 25.5961L113.448 21.8978H114.248V26.8955H113.648V23.3971V22.5975L113.348 23.3971L111.749 26.9955H111.249L109.75 23.3971L109.45 22.5975V23.3971V26.9955H108.851V21.9978H109.75V21.8978Z"
				fill="black"
			/>
			<path
				d="M115.847 21.8978H119.646V26.8955H119.046V22.4975H116.447V26.9955H115.847V21.8978V21.8978Z"
				fill="black"
			/>
			<path
				d="M121.445 22.7974V22.1977C121.945 21.9978 122.444 21.8978 122.944 21.8978C123.444 21.8978 123.844 21.9978 124.144 22.1977C124.443 22.3976 124.543 22.6974 124.643 22.8973C124.743 23.0973 124.743 23.3971 124.743 23.697V25.796V26.8955H124.244V26.0959C123.944 26.6956 123.344 26.9955 122.644 26.9955C122.244 26.9955 121.945 26.8955 121.745 26.7956C121.545 26.6956 121.345 26.4957 121.245 26.2958C121.145 26.0959 121.045 25.896 121.045 25.5961C121.045 24.8964 121.345 24.4966 121.845 24.1968C122.344 23.9968 123.044 23.8969 123.944 23.8969H124.244V23.697C124.244 22.7974 123.844 22.3976 123.044 22.3976C122.344 22.3976 121.845 22.4975 121.445 22.7974ZM124.044 24.3967H123.444C122.844 24.3967 122.344 24.4966 122.045 24.6965C121.745 24.8964 121.545 25.1963 121.545 25.5961C121.545 25.796 121.545 25.9959 121.645 26.0959C121.745 26.1958 121.845 26.3958 122.045 26.3958C122.144 26.4957 122.344 26.4957 122.544 26.4957C122.844 26.4957 123.144 26.3958 123.444 26.2958C123.744 26.1958 123.844 25.896 123.944 25.6961C124.044 25.3962 124.144 25.0963 124.144 24.7965V24.3967H124.044Z"
				fill="black"
			/>
			<path
				d="M126.243 21.8978H126.842V23.9968H129.541V21.8978H130.141V26.8955H129.541V24.5966H126.842V26.8955H126.243V21.8978Z"
				fill="black"
			/>
			<path
				d="M131.74 21.8978H132.34V25.5961V26.0959L132.64 25.5961L135.239 21.8978H135.938V26.8955H135.338V23.2972V22.7974L135.039 23.2972L132.44 26.9955H131.74V21.8978Z"
				fill="black"
			/>
			<path
				d="M137.537 21.8978H138.137V25.5961V26.0959L138.437 25.5961L141.036 21.8978H141.736V26.8955H141.136V23.2971V22.7974L140.836 23.2971L138.237 26.9954H137.537V21.8978ZM138.237 19.9986L138.537 19.6988C138.837 20.0986 139.237 20.2985 139.637 20.2985C140.036 20.2985 140.436 20.0986 140.736 19.6988L141.036 19.9986C140.836 20.1985 140.636 20.3984 140.436 20.5984C140.236 20.6983 139.936 20.7983 139.637 20.7983C139.337 20.7983 139.037 20.6983 138.837 20.5984C138.637 20.3984 138.437 20.2985 138.237 19.9986Z"
				fill="black"
			/>
		</svg>
	)
}

export default LogoIcon
